import React, { useState } from "react";
import { B2BHeader } from "@carfax/web-ui/components/B2BHeader";
import { ModuleCampaignBanner } from "contentful/PageModularModule";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import router from "next/router";
import { VinSearchCtxProvider } from "components/VinSearch/VinSearchContext";
import VinSearch, { SearchDisplayType } from "components/VinSearch/VinSearch";
import ModalWhereIsVin from "../ModalWhereIsVin";

interface Props {
  module: ModuleCampaignBanner;
}

const CampaignBanner: React.FC<Props> = ({
  module: {
    title,
    description,
    ctaButtonLabel,
    ctaButtonUrl,
    image,
    anchorLinkLabel,
    anchorElementId,
    partnerLogo,
    partnerNoteText,
    displayVinSearch,
    modalText,
    themePattern,
    customPlaceholder,
    trustIconsText,
    trustIcons,
    withSearchButtonText,
  },
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const onClickWhereIsVin = (event: any) => {
    event.preventDefault();
    setModalOpen(true);
  };
  const handleOnClick: React.MouseEventHandler = (e) => {
    if (ctaButtonUrl) {
      e.preventDefault();
      router.push(ctaButtonUrl);
    }
  };
  const vinSearchProps = {
    displayType: SearchDisplayType.DefaultWithoutContainer,
    displayBelow: false,
    withSearchButtonText: withSearchButtonText,
  };
  return (
    <>
      <B2BHeader
        title={title}
        description={description ? documentToReactComponents(description) : null}
        ctaText={ctaButtonLabel}
        foxImage={image}
        ctaUrl={ctaButtonUrl}
        ctaOnClick={handleOnClick}
        additionalLinkElementId={anchorElementId}
        additionalLinkText={anchorLinkLabel}
        partnerNote={partnerNoteText}
        partnerLogo={partnerLogo}
        themePattern={themePattern}
        trustIconsText={trustIconsText}
        trustIcons={trustIcons}
        vinSearch={
          displayVinSearch && (
            <VinSearchCtxProvider>
              <VinSearch {...vinSearchProps} customPlaceholder={customPlaceholder} />
            </VinSearchCtxProvider>
          )
        }
        modalText={!!modalText && <div onClick={onClickWhereIsVin}>{modalText}</div>}
      />
      {!!modalText && modalOpen && <ModalWhereIsVin modalOpen={modalOpen} setModalOpen={setModalOpen} />}
    </>
  );
};

export default CampaignBanner;
