const renderTable = (node) => {
  return (
    <table>
      {node.content.map((table, index) => {
        const tableContent = table.content;
        const tableRow = tableContent.map((row) => row.content[0].content);
        return (
          <tr key={index}>
            {tableRow.map((tableCell, cellIndex) => (
              <td key={cellIndex}>{tableCell[0].value}</td>
            ))}
          </tr>
        );
      })}
    </table>
  );
};

export const tableRenderer = {
  renderTable,
};
