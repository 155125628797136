import * as React from "react";
import { styles } from "./CarDetails.styles";

export interface ICarDetailsProps {
  carImage?: string;
  details: { name: string; value: string }[];
}

export function CarDetails({ details, carImage = "" }: ICarDetailsProps) {
  const showDetails = details.some((detail) => detail.value);

  const onImageError = (e) => {
    e.target.src = require("./images/car-fallback.svg");
  };

  if (!showDetails && !carImage) return null;
  return (
    <div data-testid="car-details" css={styles.description}>
      {showDetails && (
        <div css={styles.details}>
          {details.map(
            ({ name, value }) =>
              value && (
                <div key={name} css={styles.detail}>
                  <div css={styles.name}>
                    <h4>{name}</h4>
                  </div>
                  <div>{value}</div>
                </div>
              )
          )}
        </div>
      )}
      <div css={styles.carImage}>
        <img src={carImage} alt="" title="" onError={onImageError} />
      </div>
    </div>
  );
}
