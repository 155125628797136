import React from "react";
import { Button } from "../../../../elements/Button";
import { styles } from "./ContentAfterRecordCheck.styles";

export interface IContentAfterRecordCheckProps {
  enterVinMessage: string;
  afterVinCheck: string;
  vhrFound: string;
  isVinValid: boolean;
  ctaLink: string;
  ctaText: string;
  VinSearchComponent: React.ReactNode;
}

export const ContentAfterRecordCheck = ({
  enterVinMessage,
  afterVinCheck,
  vhrFound,
  isVinValid,
  ctaLink,
  ctaText,
  VinSearchComponent,
}: IContentAfterRecordCheckProps) => (
  <>
    {isVinValid ? (
      <>
        <div css={styles.vhrFound} dangerouslySetInnerHTML={{ __html: vhrFound }} />
        <div data-testid="car-submission-button" css={styles.ctaWrapper}>
          <Button href={ctaLink}>{ctaText}</Button>
        </div>
      </>
    ) : (
      <>
        <div css={styles.vinMessage} dangerouslySetInnerHTML={{ __html: enterVinMessage }} />
        {VinSearchComponent}
        <div css={styles.vinMessage} dangerouslySetInnerHTML={{ __html: afterVinCheck }} />
      </>
    )}
  </>
);
