import React, { useState, useEffect } from "react";
import { styles } from "./BolloCalculator.style";
import { SelectInput } from "../../../elements/SelectInput";
import { Input } from "../../../elements/InputNew/Input";
import { Button } from "../../../elements/Button";
import { taxConfig } from "../taxConfig";
import { usePiemonteRegionCalculator } from "./utils/usePiemonteRegionCalculator";
import { getValueFromArray } from "./utils/getValueFromArray";
import { useErrorHandler } from "./utils/useErrorHandler";

export interface BolloCalculatorProps {
  regionText: string;
  euroNormText: string;
  enginePowerText: string;
  calculateText: string;
  summaryText: string;
  enginePowerPlaceholder: string;
  regionPlaceholder: string;
  euroNormPlaceholder: string;
  errorMessage: string;
}

export const enum EnginePower {
  EQUAL_100KW = 100,
  EQUAL_131KW = 131,
  EQUAL_51KW = 51,
}

export const BolloCalculator: React.FC<BolloCalculatorProps> = ({
  regionText,
  euroNormText,
  enginePowerText,
  calculateText,
  summaryText,
  enginePowerPlaceholder,
  euroNormPlaceholder,
  regionPlaceholder,
  errorMessage,
}) => {
  const basicValue = { name: euroNormPlaceholder, value: 0 };
  const [regionName, setRegionName] = useState(regionPlaceholder);
  const [euroNorm, setEuroNorm] = useState(basicValue);
  const [enginePower, setEnginePower] = useState(0);
  const [calculatedTax, setCalculatedTax] = useState(0);
  const [additionalTax, setAdditionalTax] = useState(0);

  const { regionError, enginePowerError, euroNormError, validateFields, setValidationPerformed } = useErrorHandler(
    regionName,
    euroNorm,
    enginePower,
    regionPlaceholder,
    euroNormPlaceholder
  );

  const { piemonteCalculator, isPiemonteRegion, additionalTaxTable, maxEnginePower100 } = usePiemonteRegionCalculator(
    enginePower,
    regionName
  );
  const euroNormForRegion = getValueFromArray(taxConfig, regionName);

  const euroNormTable = () => {
    if (regionName !== regionPlaceholder) {
      if (isPiemonteRegion) {
        return piemonteCalculator();
      }
      return euroNormForRegion.below100KW;
    }
    return taxConfig[0].below100KW;
  };

  const calculateTax = () => {
    if (enginePower < EnginePower.EQUAL_100KW && isPiemonteRegion) {
      return Math.round(euroNorm.value * enginePower);
    }
    return Math.round(maxEnginePower100 * euroNorm.value + additionalTax);
  };

  const changeEnginePower = (e) => {
    if (regionName !== regionPlaceholder) {
      setEnginePower(e.target.value);
    }
    return null;
  };

  useEffect(() => {
    if (enginePower > EnginePower.EQUAL_100KW) {
      const taxTable = isPiemonteRegion ? additionalTaxTable() : euroNormForRegion?.above100KW;
      setAdditionalTax((enginePower - EnginePower.EQUAL_100KW) * getValueFromArray(taxTable, euroNorm.name).value);
    } else if (enginePower < EnginePower.EQUAL_100KW) {
      setAdditionalTax(0);
    }
  }, [enginePower]);

  useEffect(() => {
    if (euroNorm != basicValue) {
      setEuroNorm(getValueFromArray(euroNormTable(), euroNorm.name));
    }
  }, [enginePower]);

  return (
    <div css={styles.root()}>
      <div css={styles.wrapper()}>
        <SelectInput
          testId="select-region"
          label={regionText}
          hideAfterSelect
          placeholder={regionName}
          hasError={regionError}
          message={regionError && errorMessage}
        >
          {taxConfig.map((region) => (
            <div key={region.name} onClick={() => setRegionName(region.name)} css={styles.option()}>
              {region.name}
            </div>
          ))}
        </SelectInput>
        <SelectInput
          testId="select-euroNorm"
          label={euroNormText}
          hideAfterSelect
          placeholder={euroNorm.name}
          hasError={euroNormError}
          message={euroNormError && errorMessage}
        >
          {euroNormTable()?.map((value) => (
            <div key={value.name} onClick={() => setEuroNorm(value)} css={styles.option()}>
              {value.name}
            </div>
          ))}
        </SelectInput>
        <Input
          labelText={enginePowerText}
          onChange={(e) => changeEnginePower(e)}
          type="number"
          min="1"
          max="999"
          placeholder={enginePowerPlaceholder}
          hasError={enginePowerError}
          message={enginePowerError && errorMessage}
        />
        <Button
          testSelector="calculate-button"
          css={styles.button()}
          onClick={() => {
            setCalculatedTax(calculateTax());
            validateFields();
            setValidationPerformed(true);
          }}
        >
          {calculateText}
        </Button>
      </div>
      <div css={styles.summary()}>
        <div>{summaryText}</div>
        <div css={styles.taxValue()}>{calculatedTax === 0 ? "-" : `${calculatedTax}€`}</div>
      </div>
    </div>
  );
};
